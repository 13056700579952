<template>
  <ion-segment
    scrollable
    mode="md"
    color="primary"
    class="tabs compact no-shadow bg-transparent"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button v-for="(tab, index) of tabs" :key="index" class="shrink bg-transparent" :value="index">
      <ion-label
        >{{ tab.name }}
        <span v-if="!isEmpty(selectedCategory)">{{
          get(selectedCategory, 'key') !== '' ? pluralize(selectedCategory.value) : ''
        }}</span></ion-label
      >
    </ion-segment-button>
  </ion-segment>
</template>

<script lang="ts" setup>
import pluralize from 'pluralize';

import { Tab } from '@/shared/types/static-types';

pluralize.addUncountableRule('Art');

const props = defineProps({
  tabs: {
    type: Array,
    default: [] as Tab[],
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
  selectedCategory: {
    type: Object,
    default: () => ({}),
  },
});

const tabs = toRef(props, 'tabs');
const activeIndex = toRef(props, 'activeIndex');
const emits = defineEmits(['update:activeIndex', 'tab-changed']);
const segmentChanged = ($event: CustomEvent) => {
  const tabIndex = $event.detail.value as number;
  const tab = tabs.value[tabIndex];

  emits('update:activeIndex', tabIndex);
  emits('tab-changed', tab);
};
</script>

<style scoped lang="sass">
.shrink
  flex: none !important
ion-segment
  height: 46px !important
ion-segment-button
  height: 46px !important
  min-height: 46px !important
  max-height: 46px !important
</style>
