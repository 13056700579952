<template>
  <div>
    <ion-page class="page scrollable bg-transparent">
      <div class="d-flex justify-content-between align-items-center flex-wrap mt-4">
        <div>
          <h1 class="my-0"><strong>Explore Posts</strong></h1>
        </div>
        <div class="ml-auto d-flex">
          <div class="mt-1 mr-2">
            <multiselect
              v-model="selectedPostOption"
              class="choose-options"
              placeholder="Filter"
              :options="postOptions"
              select-label=""
              :disabled="loader"
              :loading="loader"
              deselect-label=""
              :multiple="false"
              :taggable="false"
              open-direction="below"
              :close-on-select="true"
              :searchable="false"
              track-by="key"
              label="value"
            >
              <template #noOptions>You don't have a post yet, go make one!</template>
            </multiselect>
          </div>
          <ion-button class="text-white posts-btn" style="width: 80px; height: 33px" @click="createPost"
            >Post</ion-button
          >
        </div>
      </div>

      <blabs-tabs
        class="my-2"
        :tabs="tabs"
        :active-index="tabIndex"
        :selected-category="selectedPostOption"
        @tab-changed="tabChanged"
      />
      <all-posts :posts="categoryPosts" :total-posts="totalPosts" :loader="loader" @loadMore="loadMore" />
    </ion-page>
  </div>
</template>

<script lang="ts" setup>
import Multiselect from 'vue-multiselect';
import BlabsTabs from './components/BlabsTabs.vue';
import AllPosts from '@/shared/components/Posts/AllPosts.vue';
import { blabsTabs } from '@/shared/statics/tabs';
import { Tab } from '@/shared/types/static-types';
import { getPosts } from '@/shared/actions/blabs';

const selectedPostOption = ref({ value: 'All Posts', key: '' });
const tabs = ref(blabsTabs);
const currentTab = ref('trending');
const loader = ref(false);
const firstLoad = ref(false);
const categoryPosts = ref([]);
const tabIndex = ref(0);
const totalPosts = ref(0);
const pageNum = ref(1);

const router = useRouter();

const postOptions: any = [
  { value: 'All Posts', key: '' },
  { value: 'Art', key: 'art' },
  { value: 'Art WIP', key: 'art_wip' },
  { value: 'Story', key: 'story' },
  { value: 'Comic', key: 'comic' },
  { value: 'Meme', key: 'meme' },
  { value: 'Personal Update', key: 'personal_update' },
  { value: 'Roleplay', key: 'roleplay' },
  { value: 'Journals', key: 'journal' },
  { value: 'Social Post', key: 'social_post' },
  { value: 'Q&A', key: 'question_and_answer' },
];

watch(selectedPostOption, () => {
  if (firstLoad.value) {
    categoryPosts.value = [];
    pageNum.value = 1;
    // if (selectedPostOption.value) {
    // if (selectedPostOption.value.key !== '') {
    router.replace({
      name: 'posts-tabs',
      query: {
        ...router.currentRoute.value.query,
        type: selectedPostOption.value ? selectedPostOption.value.value : postOptions[0].value,
      },
    });
    // }
    // }
    getCategoryCurrentPosts();
  }
});

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  categoryPosts.value = [];
  pageNum.value = 1;

  getCategoryCurrentPosts();
  router.replace({
    name: 'posts-tabs',
    query: { ...router.currentRoute.value.query, tab: value },
  });
};

const getCategoryCurrentPosts = () => {
  currentTab.value === 'trending'
    ? fetchCategoryPosts('-hot_score')
    : currentTab.value === 'top'
    ? fetchCategoryPosts('-reaction_count')
    : fetchCategoryPosts('-created');
};

const fetchCategoryPosts = async (order: string) => {
  loader.value = true;
  const resp = await getPosts(pageNum.value, 40, {
    ordering: order,
    ...(!!selectedPostOption.value && { category: `${selectedPostOption.value.key}` }),
  });

  categoryPosts.value = categoryPosts.value.concat(resp.results);
  totalPosts.value = resp.count;

  loader.value = false;
  firstLoad.value = true;
};

const createPost = () => {
  router.push('/posts/create');
};

const loadMore = () => {
  pageNum.value++;

  getCategoryCurrentPosts();
};

onMounted(() => {
  const document = useDocument();
  document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

  if (router.currentRoute.value.query.type) {
    const selectedPost = postOptions.find((option: any) => option.value === router.currentRoute.value.query.type);
    if (selectedPost) {
      selectedPostOption.value = selectedPost;
    }
  }

  nextTick(() => {
    const tIndex = indexOf(
      tabs.value,
      find(tabs.value, (tab) => tab.value === (router.currentRoute.value.query.tab as string))
    );
    if (router.currentRoute.value.query.tab && tIndex !== -1) {
      tabIndex.value = tIndex;
      currentTab.value = router.currentRoute.value.query.tab as string;
    }
  });

  getCategoryCurrentPosts();
});
</script>

<style lang="sass" scoped>
.choose-options
  width: 200px
  @media(max-width: 370px)
    width: 100px
  @media(max-width: 250px)
    width: 90px
::v-deep
  .multiselect--active .multiselect__select
    transform: rotateZ(0deg) !important
    border-bottom-right-radius: 0px !important

  .multiselect--active .multiselect__tags
    border-bottom-left-radius: 0px !important
    border-bottom-right-radius: 0px !important

::v-deep .multiselect
  .multiselect__tags
    border-radius: 12px
    border: 3px solid var(--ion-color-primary)
    height: 34px
    min-height: 34px !important
    padding: 5px 40px 0 8px
  .multiselect__content-wrapper
    border-radius: 15px !important
    border-top-left-radius: 0px !important
    border-top-right-radius: 0px !important

  .multiselect__select
    height: 32px !important
    background: var(--ion-color-primary)
    width: 35px !important
    border-top-right-radius: 15px
    border-bottom-right-radius: 15px
    line-height: 16px !important
    right: 0px
  .multiselect__select::before
    border-color: #fff transparent transparent transparent !important
  .multiselect__single
    line-height: 18px !important
  .multiselect__placeholder
    padding-top: 0px
  .multiselect__spinner
    height: 32px !important
    background: var(--ion-color-primary)
    width: 35px !important
    border-top-right-radius: 15px
    border-bottom-right-radius: 15px
    line-height: 16px !important
  .multiselect__spinner:before, .multiselect__spinner:after
    border-color: #ffffff transparent transparent !important
</style>
